import React, { useState } from "react";
import "./MobileNavMenu.css";
import logo from "../../Images/earth-beauty-you-logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faMagnifyingGlass,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import Cart from "../Cart/Cart";
import { Link } from "react-router-dom";
import PopCart from "../PopCart/PopCart";
import MobileSearch from "../MobileSearch/MobileSearch";
import MobileCategory from "../MobileCategory/MobileCategory";


const MobileNavMenu = ({ popCart, handlePopCart }) => {
  const [userLogin, setUserLogin] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [menu, setMenu] = useState(false);


  const handleSearch = (search) => {
    setShowSearch(search);
  };

  const handleMenu = (menu) => {
    setMenu(menu);
    setShowSearch(false)
    if (menu) {
      handlePopCart(false);
    }
  };

  if (popCart || userLogin || showSearch || menu) {
    if (window.innerWidth < 640) {
      document.body.style.overflow = "hidden";
    }
  } else {
    document.body.style.overflow = "visible";
  }

  return (
    <div
      className={`mobile-menu-container ${
        showSearch ? "searched-nav-menu" : ""
      }`}
    >
      <PopCart handlePopCart={handlePopCart} popCart={popCart}></PopCart>

      <div className="flex ">
        <FontAwesomeIcon
          className="text-secondary mr-8 text-xl"
          icon={faBars}
          onClick={() => handleMenu(true)}
        ></FontAwesomeIcon>
        <MobileCategory menu={menu} handleMenu={handleMenu} />

        <FontAwesomeIcon
          onClick={() => handleSearch(!showSearch)}
          className="text-secondary text-xl"
          icon={faMagnifyingGlass}
        ></FontAwesomeIcon>
        <MobileSearch
          showSearch={showSearch}
          handleSearch={handleSearch}
        ></MobileSearch>
      </div>

      <Link to="/">
        <img className="w-[50%] mx-auto px-4" src={logo} alt="" width={334} height={201} title="Earth Beauty & You" />
      </Link>
      <Link className="ml-auto" to="/customerDashboard">
        <FontAwesomeIcon
          className="text-secondary text-2xl absolute right-16 bottom-[26px]"
          icon={faUser}
        ></FontAwesomeIcon>
        </Link>

      <div className="ml-auto">

        <Cart
          popCart={popCart}
          handlePopCart={handlePopCart}
          handleSearch={handleSearch}
        ></Cart>
      </div>
      <span
        className={`overlay ${
          showSearch ? "searched-overlay" : "not-searched-overlay"
        } ${userLogin ? "overlay-active" : ""} ${
          popCart || menu ? "overlay-active " : ""
        }`}
      ></span>
    </div>
  );
};

export default MobileNavMenu;
