import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="bg-secondary overflow-hidden footer-container">
      <footer className=" pt-16 pb-28  text-white  2xl:w-[65%] lg:w-[75%] w-[80%] mx-auto">
        <div className="grid grid-cols-2 gap-10 mx-auto lg:flex lg:flex-row lg:justify-between ">
          <div className="flex flex-col text">
            <span className="font-bold mb-4">GET TO KNOW US</span>
            <Link to="/terms" className="hover:text-black ease-in-out duration-200 font-bold">Terms of Service</Link>
            <Link to="/privacyPolicy" className="hover:text-black ease-in-out duration-200 font-bold">Privacy Policy</Link>
            <Link to="/refund" className="hover:text-black ease-in-out duration-200 font-bold">Refund policy</Link>
            <Link to="/shippingPolicy" className="hover:text-black ease-in-out duration-200 font-bold mt-8">Shipping Policy</Link>
            <Link to="/about" className="hover:text-black ease-in-out duration-200 font-bold">About Us</Link>
          </div>
          <div className="flex flex-col">
            <span className="font-bold mb-4">CATEGORIES</span>
            <Link to={`/product-category/face wash`} className="hover:text-black ease-in-out duration-200 font-bold">Face Wash</Link>
            <Link to={`/product-category/lotion`} className="hover:text-black ease-in-out duration-200 font-bold">Lotion</Link>
            <Link to={`/product-category/mist toner`} className="hover:text-black ease-in-out duration-200 font-bold">Mist Toner</Link>
            <Link to={`/product-category/oil`} className="hover:text-black ease-in-out duration-200 font-bold">Oil</Link>
            <Link to={`/product-category/hand wash`} className="hover:text-black ease-in-out duration-200 font-bold">Hand Wash</Link>
            <Link to={`/newarrivals`} className="hover:text-black ease-in-out duration-200 font-bold">New Arrivals</Link>
          </div>
          <div className="flex flex-col">
            <span className="font-bold mb-4">CUSTOMER CARE</span>
            <Link to="/refund" className="hover:text-black ease-in-out duration-200 font-bold">Refund And Return Policy</Link>
            <Link to="/shippingPolicy" className="hover:text-black ease-in-out duration-200 font-bold">Shipping Policy</Link>
            <Link to="/privacyPolicy" className="hover:text-black ease-in-out duration-200 font-bold">Privacy Policy</Link>
          </div>
          <div className="flex flex-col hidden sm:block">
            <span className="font-bold mb-4">CONTACT US</span>
            <p className="text-black text-opacity-80 ease-in-out duration-200 font-bold mt-4">
            Email: <span className="text-primary">care@earthbeautyandyou.com</span>
            </p>
            <p className="text-black text-opacity-80 ease-in-out duration-200 font-bold">
            Phone: <span className="text-primary block sm:inline">+8801 404 403 965</span>
            </p>
            <p className="text-black text-opacity-80 ease-in-out duration-200 font-bold">
            International Enquiries : <span className="text-primary">imtiazazhz@gmail.com</span>
            </p>
            <p className="text-black text-opacity-80 ease-in-out duration-200 font-bold">
            Influncer & Blogger Enquiries : <span className="text-primary">kashfia.giv@gmail.com</span>
            </p>
       
            <a target="_blank" rel="noreferrer" className="hover:text-black ease-in-out duration-200 flex justify-center items-center font-bold border border-white w-[200px] p-4 mt-4 "href="https://drive.google.com/file/d/14KnH6vQSbnedA06yyCBnPPEB9kRA8b_3/view?usp=sharing">Download Catalogue</a>
            <div className="flex mt-4 gap-6">
              <a target="_blank" rel="noreferrer" className="bg-primary w-10 h-10 flex justify-center items-center rounded-full" href="https://www.facebook.com/EarthBeautyandYou"><svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512"><path fill="#5E6693" d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/></svg></a>
              <a target="_blank" rel="noreferrer" className="bg-primary w-10 h-10 flex justify-center items-center rounded-full" href="https://www.instagram.com/earthbeautyandyou/"><svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg></a>
              <a target="_blank" rel="noreferrer" className="bg-primary w-10 h-10 flex justify-center items-center rounded-full" href="https://www.youtube.com/@earthbeautyandyou">
              <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 576 512"><path fill="#F4080D" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/></svg>
              </a>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-10 gap-4 sm:hidden">
            <span className="font-bold mb-4">CONTACT US</span>
            <p className="text-black text-opacity-80 ease-in-out duration-200 font-bold">
            Email: <span className="text-primary">care@earthbeautyandyou.com</span>
            </p>
            <p className="text-black text-opacity-80 ease-in-out duration-200 font-bold">
            Phone: <span className="text-primary block sm:inline">+8801 404 403 965</span>
            </p>
            <p className="text-black text-opacity-80 ease-in-out duration-200 font-bold">
            International Enquiries : <span className="text-primary">imtiazazhz@gmail.com</span>
            </p>
            <p className="text-black text-opacity-80 ease-in-out duration-200 font-bold">
            Influncer & Blogger Enquiries : <span className="text-primary">kashfia.giv@gmail.com</span>
            </p>
       
            <a target="_blank" rel="noreferrer" className="hover:text-black ease-in-out duration-200 flex justify-center items-center font-bold border border-white w-[200px] p-4 mt-4 "href="https://drive.google.com/file/d/14KnH6vQSbnedA06yyCBnPPEB9kRA8b_3/view?usp=sharing">Download Catalogue</a>
            <div className="flex mt-4 gap-6">
              <a target="_blank" rel="noreferrer" className="bg-primary w-10 h-10 flex justify-center items-center rounded-full" href="https://www.facebook.com/EarthBeautyandYou"><svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 512 512"><path fill="#5E6693" d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z"/></svg></a>
              <a target="_blank" rel="noreferrer" className="bg-primary w-10 h-10 flex justify-center items-center rounded-full" href="https://www.instagram.com/earthbeautyandyou/"><svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 448 512"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg></a>
              <a target="_blank" rel="noreferrer" className="bg-primary w-10 h-10 flex justify-center items-center rounded-full" href="https://www.youtube.com/@earthbeautyandyou">
              <svg xmlns="http://www.w3.org/2000/svg" height="1.5em" viewBox="0 0 576 512"><path fill="#F4080D" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/></svg>
              </a>
            </div>
          </div>
      </footer>
      <div className="text-center bg-primary py-10 px-4 lg:p-8 lg:mb-0">
        <p>Copyright © {year} - All right reserved by Earth Beauty & You</p>
      </div>
    </div>
  );
};

export default Footer;
